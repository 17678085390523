<template>
  <BaseLayout>
    <template #header>
      <h1>Pelabelan dan Penimbangan</h1>

      <section
        id="filter-section"
        class="p-3 rounded"
      >
        <b-row class="align-items-center">
          <b-col cols="12">
            <b-form-group
              label="Masukkan Barcode"
              label-for="start-date"
            >
              <b-form-input
                id="barcode-input"
                v-model="formData.barcode"
                type="text"
                @input="debounceCheckedBarcode"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-3">
          <b-col cols="12">
            <b-form-group
              label="Merk"
              label-for="input-merk"
            >
              <b-form-select
                id="input-merk"
                v-model="formData.brand"
                :options="options"
                class="mb-1"
                placeholder="Pilih Merk Kantong"
                @change="updateAllMili"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    Pilih Jenis Merk
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </section>
    </template>
    <template #content>
      <b-row class="d-flex justify-content-center">
        <b-col cols="auto">
          <div class="table-wrapper w-100 mx-auto">
            <b-table
              class="table-borderless table-responsive w-100 overflow-auto"
              style="white-space: nowrap"
              :fields="fields"
              :busy.sync="isBusy"
              :items="items"
              show-empty
            >
              <template #empty>
                <div class="hp-p1-body text-center my-2">
                  <strong>Tidak Ada Data</strong>
                </div>
              </template>
              <template #table-busy>
                <div class="hp-p1-body text-center my-2">
                  <b-spinner class="align-middle mr-8" />
                  <strong>Memuat...</strong>
                </div>
              </template>

              <template #cell(action)="{ index }">
                <b-button
                  variant="danger"
                  size="sm"
                  class="mr-1"
                  @click="removeItem(index)"
                >
                  <i class="ri-delete-bin-6-line" />
                </b-button>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <b-row class="d-flex justify-content-center">
        <b-col cols="auto">
          <div class="w-100 mx-auto overflow-scroll">
            <b-table
              v-if="showInputItemsTable"
              class="table-borderless table-responsive w-100"
              :fields="inputFields"
              :busy.sync="isBusy"
              :items="tableItems"
              show-empty
            >
              <!-- Custom cell templates -->
              <template #cell(barcode)="data">
                <b-form-input
                  v-model="data.item.barcode"
                  disabled
                  type="text"
                  placeholder="Enter barcode"
                  style="min-width: 150px"
                />
              </template>

              <template #cell(weight)="data">
                <b-form-input
                  v-model.number="data.item.weight"
                  :disabled="data.item.status === false"
                  type="number"
                  placeholder="Enter weight (gr)"
                  style="min-width: 120px"
                  @input="convertGramToMilli(data.index)"
                />
              </template>

              <template #cell(mili)="data">
                <b-form-input
                  v-model.number="data.item.mili"
                  :disabled="data.item.status === false"
                  type="number"
                  style="min-width: 120px"
                />
              </template>

              <template #cell(status)="data">
                <b-form-checkbox
                  v-model="data.item.status"
                  switch
                  @change="updateExpiredDate(data.index)"
                >
                  {{ data.item.status ? "Diterima" : "Ditolak" }}
                </b-form-checkbox>
              </template>

              <template #cell(reason_rejected)="data">
                <b-form-select
                  v-model="data.item.reason_rejected"
                  :options="reasonRejectedOptions"
                  :disabled="data.item.status"
                  style="min-width: 195px"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                    >
                      Pilih Alasan Ditolak
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </template>

              <template #cell(expired)="data">
                <b-form-input
                  v-model="data.item.expired"
                  type="date"
                  disabled
                  style="min-width: 200px"
                />
              </template>
              <template #empty>
                <div class="hp-p1-body text-center my-2">
                  <strong>Tidak Ada Data</strong>
                </div>
              </template>
              <template #table-busy>
                <div class="hp-p1-body text-center my-2">
                  <b-spinner class="align-middle mr-8" />
                  <strong>Memuat...</strong>
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mt-3 mb-3"
          :disabled="!tableItems.some((item) => (item.status === true || item.reason_rejected !== null && item.reason_rejected !== ''))"
          @click="postBloodProcess"
        >
        <b-spinner
          v-if="isLoading"
          small
        />
        Simpan
        </b-button>
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import manageBloodProcessAPI from '@/api/blood_process/manageBloodProcessAPI'
import mapBloodType from '@/utils/mapBloodType'
import { debounce } from 'lodash'

export default {
  name: 'AddBloodShipments',
  components: {
    BSpinner,
    BFormSelect,
    BFormInput,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BTable,
    BFormCheckbox,
    BFormSelectOption,
  },
  data() {
    return {
      // kode awal dari database
      mainBarcode: '',
      // status boolean untuk menentukan apakah table input items ditampilkankan
      showInputItemsTable: false,
      jenisKantongforShowInputItemsTable: '',
      // tanggal aftap
      ExpiredDateFromAftapDate: '',
      formData: {
        // state untuk checkbox switches
        checked: [],
        brand: '',
        barcode: '',
        weight: 0,
        mili: 0,
      },
      // data untuk densiti merk
      componentDensities: {
        PRC: 1.095,
        TC: 1.032,
        WB: 1.055,
        LP: 1.027,
        AHF: 1.095,
        FFP: 1.095,
        'PCL-PRC-BAYI': 1.095,
      },
      // data untuk kadaluarsa kantong darah
      expirationPeriods: {
        ahf: 365,
        ffp: 365,
        lp: 40,
        prc: 35,
        'pcl-prc-bayi': 35,
        tc: 5,
        wb: 35,
      },
      // state untuk button
      isLoading: false,
      // state untuk table
      isBusy: false,
      // untuk menampung data barcodes
      barcodesCollected: [],
      // untuk jeda debounce barcode
      debounceCheckedBarcode: null,
      // untuk data item opsi dan tabel pada merk
      options: [
        { value: 'Haemopack', text: 'Haemopack' },
        { value: 'Terumo', text: 'Terumo' },
        { value: 'Compoflex', text: 'Compoflex' },
      ],
      // untuk data item opsi dan tabel pada alasan gagal
      reasonRejectedOptions: [
        { value: 'Lipemik', text: 'Lipemik' },
        { value: 'CLOT', text: 'CLOT' },
        { value: 'Lisis/Pecah', text: 'Lisis/Pecah' },
        { value: 'Over Volume', text: 'Over Volume' },
        { value: 'Volume Kurang', text: 'Volume Kurang' },
      ],
      fields: [
        {
          key: 'no',
          label: 'No',
          sortable: true,
        },
        {
          key: 'barcode',
          label: 'Barcode',
          sortable: false,
        },
        {
          key: 'blood_bag_number',
          label: 'Nomor Selang',
          sortable: true,
        },
        {
          key: 'blood_type',
          label: 'Golongan Darah',
        },
        {
          key: 'bag_type',
          label: 'Jenis Kantong',
        },
        {
          key: 'action',
          label: 'Aksi',
        },
      ],
      items: [],
      inputFields: [
        {
          key: 'jenis',
          label: 'Jenis',
        },
        {
          key: 'barcode',
          label: 'Barcode',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'weight',
          label: 'Berat(gr)',
        },
        {
          key: 'mili',
          label: 'Mili',
        },
        {
          key: 'reason_rejected',
          label: 'Alasan Ditolak',
        },
        {
          key: 'expired',
          label: 'Tanggal Kadaluarsa',
        },
      ],
      inputFieldsItem: [
        { jenis: 'Komponen PRC', value: 'PRC' },
        { jenis: 'Komponen LP', value: 'LP' },
        { jenis: 'Komponen TC', value: 'TC' },
      ],
      counter: 1,
    }
  },
  computed: {
    tableItems() {
      if (!this.showInputItemsTable) return []

      return this.inputFieldsItem
        .filter(item => {
          // kalau jenis kantongnya DB atau double, tampilkan hanya input PRC dan LP
          if (this.jenisKantongforShowInputItemsTable === 'DB') {
            return ['PRC', 'LP'].includes(item.value)
          }

          // kalau jenis kantongnya TR atau triple, tampilkan semua
          return true
        })
        .map(item => ({
          jenis: item.jenis,
          value: item.value,
          barcode: this.mainBarcode
            ? this.mainBarcode.replace(/WB$/, item.value)
            : '',
          weight: 0,
          mili: 0,
          status: false,
          reason_rejected: null,
          expired: null,
        }))
    },
  },
  created() {
    // Initialize the checked array based on the number of items
    this.formData.checked = new Array(this.items.length).fill(false)

    // initialize for debouncing barcode
    this.debounceCheckedBarcode = debounce(this.checkingBarcode, 300)
  },
  methods: {
    // converting gram to mili
    convertGramToMilli(index) {
      const item = this.tableItems[index]

      if (item.weight && this.formData.brand && item.value) {
        const weightBloodBag = parseFloat(item.weight)
        const weightByBrand = this.formData.brand === 'Terumo' ? 35 : 38
        const weightyTypeBag = this.componentDensities[item.value]

        const volume = (weightBloodBag - weightByBrand) / weightyTypeBag

        this.$set(item, 'mili', Math.round(volume))
      } else {
        this.$set(item, 'mili', null)
      }
    },
    // updating values based on the input brand
    updateAllMili() {
      this.tableItems.forEach((_, index) => this.convertGramToMilli(index))
    },
    // updating expired date
    updateExpiredDate(index) {
      const item = this.tableItems[index]

      if (item.status) {
        const component = item.value.toLowerCase()
        const daysToAdd = this.expirationPeriods[component] || 0

        if (daysToAdd) {
          const aftapDate = new Date(this.ExpiredDateFromAftapDate)
          aftapDate.setDate(aftapDate.getDate() + daysToAdd)

          const formattedDate = `${aftapDate.getFullYear()}-${String(
            aftapDate.getMonth() + 1,
          ).padStart(2, '0')}-${String(aftapDate.getDate()).padStart(2, '0')}`

          this.$set(item, 'expired', formattedDate)
        } else {
          this.$set(item, 'expired', '')
        }
      } else {
        this.$set(item, 'expired', null)
      }
    },
    // dealing with external data
    async checkingBarcode() {
      try {
        if (this.isBusy || !this.formData.barcode.trim()) return

        this.isBusy = true

        const response = await manageBloodProcessAPI.checkingBarcode({
          barcode: this.formData.barcode,
        })

        const tableItems = {
          no: this.items.length + 1, // Increment row number based on the length of the items array
          barcode: response.data.data.barcode,
          blood_bag_number: response.data.data.blood_bag_number,
          blood_type: mapBloodType(response.data.data.blood_type),
          bag_type: response.data.data.bag_type,
          aftap_date: response.data.data.aftap_date,
        }

        // Check if barcode already exists in the table to prevent duplicates
        const existingIndex = this.items.findIndex(
          item => item.barcode === tableItems.barcode,
        )

        if (existingIndex === -1) {
          // Add new barcode to the table if it doesn't exist
          this.items.push(tableItems)

          // Store the main barcode and show the second table if necessary
          this.mainBarcode = tableItems.barcode

          // Determine how many input fields should be shown based on bag type
          this.jenisKantongforShowInputItemsTable = tableItems.bag_type
          this.showInputItemsTable = ['DB', 'TR'].includes(
            this.jenisKantongforShowInputItemsTable,
          )

          // Set expiration date based on aftap_date
          this.ExpiredDateFromAftapDate = tableItems.aftap_date
        } else {
          // Handle case where barcode is already in the table (you can show a message or update)
          console.log('Barcode already exists in the table.')
        }

        // Reset barcode input field after processing
        this.formData.barcode = ''
      } catch (e) {
        console.error(e)
      } finally {
        this.isBusy = false
      }
    },

    async postBloodProcess() {
      try {
        this.isLoading = true

        const components = this.tableItems.map(item => ({
          component_barcode: this.mainBarcode.replace(/WB$/, item.value),
          component: item.value,
          weight: item.weight,
          volume: item.mili,
          expired: item.expired,
          status: item.status ? 'success' : 'failed',
          reason_failed: item.status ? null : item.reason_rejected,
        }))

        const payload = {
          main_barcode: this.mainBarcode,
          components,
        }

        const response = await manageBloodProcessAPI.storeBloodProcess(payload)

        await this.$bvToast.toast(response.data.message, {
          title: 'Data Berhasil Dikirim',
          variant: 'success',
          solid: true,
        })

        this.showInputItemsTable = false
        this.items = []
        this.isLoading = false
      } catch (error) {
        this.$bvToast.toast(error.response.data.message, {
          title: 'Terjadi Kesalahan',
          variant: 'danger',
          solid: true,
        })
        this.isLoading = false
      }
    },
    // menambahkan remove item per row ketika klik tombol dengan logo trash
    removeItem(index) {
      this.items.splice(index, 1)
      this.barcodesCollected.splice(index, 1)

      if (this.items.length === 0) {
        this.clearTableData()
      }
    },
    // aksi reset manual pada formData barcodes
    clearTableData() {
      this.items = []
      this.mainBarcode = ''
      this.showInputItemsTable = false
      this.tableItems = []
    },
  },
}
</script>

<style scoped>
.table-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.table-wrapper table {
  width: 100%;
  table-layout: fixed;
}

.table-wrapper th,
.table-wrapper td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-scroll {
  overflow-y: auto;
}
</style>
